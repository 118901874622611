<template>
  <div class="users">
    <div class="row margin-0">
      <div class="col-md-12 pad-15">
          <div class="parent">
              <div class="parent-head">
                  <ul>
                      <li :class="{'active':tab==='users'}">
                          Notifications
                      </li>
                  </ul>
              </div>
              <div class="parent-body">
                <div class="row margin-0">
                  <div class="col-6">
                    <div class="form-group">
                      <label for=""><b>Title</b></label>
                      <input type="text" v-model="payload.title" class="form-control form-control-sm">
                      <span class="error" v-if="error && error.title">{{error.title}}</span>
                    </div>
                  </div>
                  <div class="col-6"></div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for=""><b>Description</b></label>
                      <textarea name="" v-model="payload.description" class="form-control form-control-sm" id="" cols="30" rows="10"></textarea>
                      <span class="error" v-if="error && error.description">{{error.description}}</span>
                    </div>
                  </div>
                  <div class="col-12">
                    <button class="btn btn-success btn-sm margin-right-20" @click="newData()">Send</button>
                    <button class="btn btn-outline-danger btn-sm">Cancel</button>
                  </div>
                </div>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import axios from 'axios'
export default {
  data() {
    return {
      dataList: {
        results: []
      },
      productsList: [],
      selectedProduct: null,
      loading_list: false,
      pageSize: 20,
      sort_by: 'created_on',
      search_term: '',
      tab: 'users',
      user_type: 'all',
      payload: {
        title: "Happy Vacation",
        description: "Kisan365 is wishing you a happy new year.",
        target: null,
      },
      visible_form: false,
      error: {},
      uploadingStatus: null,
    };
  },
  created() {
    this.getdataList();
    this.getProductsList();
  },
  computed: {
		...mapState({
			category: (state) => state.category,
			categoryMapping: (state) => state.categoryMapping,
			subCategory: (state) => state.subCategory,
			subCategoryMapping: (state) => state.subCategoryMapping,
		}),
  },
  methods: {
    getProductsList() {
      const that = this;
      that.$cs.product
        .list({
            pageSize: 10000
        })
        .then(
          data => {
            that.productsList = data.data.results;
          },
          () => {
            console.log("error");
          }
        );
    },
    getdataList(page=null) {
      const that = this;
      let params = {
        sort: that.sort_by,
        search: that.search_term
      }
      that.user_type!='all'?params.user_type=this.user_type:null
      that.$cs.banner
        .list({
            resource: page,
            pageSize: that.pageSize,
            page,
            params 
        })
        .then(
          data => {
            that.dataList = Object.assign(data.data);
          },
          () => {
            console.log("error");
          }
        );
    },
    sortBy(field) {
      if (
        field === this.sort_by ||
        this.sort_by === `-${field}`
      ) {
        if (!this.sort_by.startsWith("-")) {
          this.sort_by = `-${field}`;
        } else {
          this.sort_by = field;
        }
      } else {
        this.sort_by = field;
      }
      this.getdataList();
    },
    clearFilter(){
      this.user_type = 'all';
      this.search_term = '';
      this.getdataList();
    },
    validateData() {
      const that = this;
      that.error = {};
      var proceed = true;
      if (that.payload.title === null || that.payload.title === "") {
        this.error.title = "Title is required";
        proceed = false;
      }
      if (that.payload.description === null || that.payload.description === "") {
        this.error.description = "description is required";
        proceed = false;
      }
      // if (that.payload.category === null || that.payload.category === "") {
      //   this.error.category = "category is required";
      //   proceed = false;
      // }
      // if (that.payload.target === null || that.payload.target === "") {
      //   this.error.target = "target is required";
      //   proceed = false;
      // }
      return proceed;
    },
    newData() {
      const that = this;
      if(this.validateData()) {
        that.processing = true
        that.$cs.notification.create({data:that.payload})
        .then(res=> {
          that.getdataList();
          that.error = {};
          that.visible_form = false;
        },
        err=> {
          if(err.response.status === 400) {
            that.error = err.response.data;
          }
        })
      }
    },
    updateData() {
      const that = this;
      if(this.validateData()) {
        that.$cs.banner.update({
            data: that.payload,
            resource: `${that.$cs.banner.api}${that.payload.id}/`,
          })
        .then(res=> {
          that.getdataList();
          that.error = {};
          that.visible_form = false;
        },
        err=> {
          if(err.response.status === 400) {
            that.error = err.response.data;
          }
        })
      }
    },
    resetPayload() {
      this.payload = {
        title: null,
        description: null,
        category: null,
        target: null,
        banner: null,
        status: true,
      };
      this.error = {};
    },
    dataEdit(item) {
      this.payload =Object.assign({}, item);
      this.visible_form = true;
    },
    uploadFile() {
      const that = this;
      const File = document.getElementById('imgfile').files[0];
      if (File !== undefined) {
        that.uploadingStatus = 'Uploading....';
        that.$cs.img.upload(File, 'banner').then(res => {
          that.payload.banner = res;
          that.uploadingStatus = "Uploaded Successfully";
        }, err => {
          that.uploadingStatus = err;
        });
      }
    }
  }
};
</script>
<style lang="stylus" scoped>
</style>