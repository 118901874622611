<template>
  <div class="users">
    <div class="row margin-0">
      <div class="col-md-12" :class="{'pad-15': (!enquiry && !user), 'pad-0': (enquiry || user)}">
        <div class="parent">
          <div class="parent-head" v-if="!enquiry && !user">
            <ul>
              <li :class="{ active: tab === 'Courses' }">Enrollments</li>
            </ul>
          </div>
          <div class="parent-body">
            <div class="row margin-0 margin-bottom-5">
              <div class="col-2 pad-0">
                <div class="form-group">
                  <label for=""><b>Search Word</b></label>
                  <input
                    type="text"
                    placeholder="Search Word"
                    v-model="search_term"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
              <!-- <div class="col-2">
                <div class="form-group">
                  <label for=""><b>Status</b></label>
                  <select
                    name=""
                    id=""
                    v-model="status"
                    class="form-control form-control-sm"
                  >
                    <option :value="null">All</option>
                    <option value="ACTIVE">ACTIVE</option>
                    <option value="NOTSTARTED">NOTSTARTED</option>
                    <option value="INIITATED">INIITATED</option>
                    <option value="PENDING">PENDING</option>
                    <option value="FAILED">FAILED</option>
                  </select>
                </div>
              </div> -->
              <div class="col-2">
                <div class="form-group">
                  <br />
                  <button
                    @click="getdataList()"
                    class="btn btn-primary btn-sm margin-right-10"
                  >
                    Search
                  </button>
                  <button @click="clearFilter();status=null;getdataList()" class="btn btn-light btn-sm">
                    Clear
                  </button>
                </div>
              </div>
              <div class="col text-right">
                <div class="form-group">
                  <br />
                </div>
              </div>
            </div>
            <table class="table sai-table fixed">
              <thead>
                <tr class="t-head">
                  <th>ENQUIRY</th>
                  <th>TEACHER</th>
                  <th>STUDENT</th>
                  <th
                    :class="{
                      active_sort: sort_by == 'status' || sort_by == '-status',
                    }"
                    @click="sortBy('status')"
                  >
                    STATUS
                    <i
                      :class="{
                        'fa-sort': sort_by != 'status',
                        'fa-sort-up': sort_by == 'status',
                        'fa-sort-down': sort_by == '-status',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th>
                  <th
                    :class="{
                      active_sort:
                        sort_by == 'created_on' || sort_by == '-created_on',
                    }"
                    @click="sortBy('created_on')"
                  >
                    CREATED ON
                    <i
                      :class="{
                        'fa-sort': sort_by != 'created_on',
                        'fa-sort-up': sort_by == 'created_on',
                        'fa-sort-down': sort_by == '-created_on',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="!loading_list">
                  <template v-for="item in dataList.results" :key="item.id">
                    <tr>
                      <td>
                        {{item.enquiry.course.title}} <br>
                        {{item.enquiry.enquiry_id}}
                      </td>
                      <td>
                        <template v-if="item.teacher.first_name">
                          {{ item.teacher.first_name }} - {{ item.teacher.user_id }}<br>
                        </template>
                        <rating-detail v-if="item.teacher.rating" :rating="item.teacher.rating" view="TEACHER" />
                      </td>
                      <td>
                        <template v-if="item.student.first_name">
                          {{ item.student.first_name }} - {{ item.student.user_id }}<br>
                        </template>
                        <rating-detail v-if="item.student.rating" :rating="item.student.rating" view="STUDENT" />
                      </td>
                      <td>
                        <status :status="item.status" />
                      </td>
                      <td>{{$filters.fullDate(item.created_on, 'DD-MMM-YYYY, h:mm A')}}</td>
                    </tr>
                  </template>
                </template>
              </tbody>
              <footer-table
                :data="dataList"
                :loading="loading_list"
                :pageSize="pageSize"
                @action="handleTableAction"
                cols="6"
              ></footer-table>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataMixin from "../mixins/dataMixin";
export default {
  props: ['enquiry', 'user'],
  data() {
    return {
      serviceModule: "enrollment",
      status: null,
      defaultPayload: {
        title: null,
        description: null,
        price: null,
        credits: null,
        order: null,
        validity: null,
        rules: null,
        status: true,
      },
    };
  },
  mixins: [DataMixin],
  methods: {
    extraParams() {
      let extra = {};
      if(this.status) {
        extra.status = this.status;
      }
      if(this.enquiry) {
        extra.enquiry = this.enquiry.id;
      }
      if(this.user) {
        extra.user = this.user.id;
        extra.owner = this.user.id;
      }
      return extra;
    },
    validateData() {
      const that = this;
      that.error = {};
      var proceed = true;
      if (
        that.payload.description === null ||
        that.payload.description === ""
      ) {
        this.error.description = "Description is required";
        proceed = false;
      }
      if (that.payload.price === null || that.payload.price === "") {
        this.error.price = "Price is required";
        proceed = false;
      }
      if (that.payload.credits === null || that.payload.credits === "") {
        this.error.credits = "Credits is required";
        proceed = false;
      }
      if (that.payload.validity === null || that.payload.validity === "") {
        this.error.validity = "Validity is required";
        proceed = false;
      }
      if (that.payload.order === null || that.payload.order === "") {
        this.error.order = "Order is required";
        proceed = false;
      }
      if (that.payload.title === null || that.payload.title === "") {
        this.error.title = "Title is required";
        proceed = false;
      }
      return proceed;
    },
  },
};
</script>
<style lang="stylus" scoped></style>