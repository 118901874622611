<template>
  <div class="users">
    <div class="row margin-0">
      <div class="col-md-12" :class="{'pad-15': !user, 'pad-0': user}">
        <div class="parent margin-bottom-15" v-if="visible_form">
          <div class="parent-body pad-0">
            <div class="row margin-0">
              <div class="col pad-0">
                <h4 v-if="!payload.id" class="text-center">Add New Subscription</h4>
                <h4 v-if="payload.id" class="text-center">Update Subscription</h4>
                <div class="row margin-0 margin-bottom-5">
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>User</b></label>
                      <p>{{ payload.user||'-' }}</p>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Type</b></label>
                      <select
                        name=""
                        id=""
                        v-model="payload.plan"
                        class="form-control form-control-sm"
                      >
                        <option :value="null">Select Plan</option>
                        <template v-if="plansList && plansList.results">
                          <template v-for="(item, idx) in plansList.results" :key="idx">
                            <option :value="item.id">{{item.title}}</option>
                          </template>
                        </template>
                      </select>
                    </div>
                    <span class="error" v-if="error && error.plan">{{
                        error.plan
                      }}</span>
                  </div>
                  <!-- <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Message</b></label>
                      <input
                        type="text"
                        v-model="payload.message"
                        class="form-control form-control-sm"
                        placeholder="Message"
                      />
                      <span class="error" v-if="error && error.message">{{
                        error.message
                      }}</span>
                    </div>
                  </div> -->
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Packs</b></label>
                      <input
                        type="number"
                        v-model="payload.packs"
                        class="form-control form-control-sm"
                        placeholder="Packs"
                      />
                      <span class="error" v-if="error && error.packs">{{
                        error.packs
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Status</b></label>
                      <select
                        name=""
                        id=""
                        v-model="payload.status"
                        class="form-control form-control-sm"
                      >
                        <option value="ACTIVE">ACTIVE</option>
                        <option value="NOTSTARTED">NOTSTARTED</option>
                        <option value="INIITATED">INIITATED</option>
                        <option value="PENDING">PENDING</option>
                        <option value="FAILED">FAILED</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Start Date</b></label>
                      <input
                        type="date"
                        v-model="payload.start_date"
                        class="form-control form-control-sm"
                        placeholder="Start Date"
                      />
                      <span class="error" v-if="error && error.start_date">{{
                        error.start_date
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>End Date</b></label>
                      <input
                        type="date"
                        v-model="payload.end_date"
                        class="form-control form-control-sm"
                        placeholder="End Date"
                      />
                      <span class="error" v-if="error && error.end_date">{{
                        error.end_date
                      }}</span>
                    </div>
                  </div>
                  <div class="col-12 text-right">
                    <button
                      v-if="!payload.id"
                      @click="newData()"
                      class="btn btn-success btn-sm margin-right-10"
                    >
                      Add New Subscription
                    </button>
                    <button
                      v-if="payload.id"
                      @click="updateData()"
                      class="btn btn-success btn-sm margin-right-10"
                    >
                      Update Subscription
                    </button>
                    <button
                      @click="visible_form = false"
                      class="btn btn-light btn-sm"
                    >
                      Cancel
                    </button>
                  </div>
                  <div class="col-12">
                    <span class="margin-0 text-danger"><b>Note:</b> You are using manually edit this record. This may effect to user and financial reports.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="parent">
          <div class="parent-head" v-if="!user">
            <ul>
              <li :class="{ active: tab === 'Courses' }">Subscriptions</li>
            </ul>
          </div>
          <div class="parent-body">
            <div class="row margin-0 margin-bottom-5">
              <div class="col-2 pad-0">
                <div class="form-group">
                  <label for=""><b>Search Word</b></label>
                  <input
                    type="text"
                    placeholder="Search Word"
                    v-model="search_term"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
              <div class="col-2">
                <div class="form-group">
                  <label for=""><b>Plan</b></label>
                  <select
                    name=""
                    id=""
                    v-model="plan"
                    class="form-control form-control-sm"
                  >
                    <option :value="null">All</option>
                    <template v-if="plansList && plansList.results">
                      <template v-for="(item, idx) in plansList.results" :key="idx">
                        <option :value="item.id">{{item.title}}</option>
                      </template>
                    </template>
                  </select>
                </div>
              </div>
              <div class="col-2">
                <div class="form-group">
                  <label for=""><b>Status</b></label>
                  <select
                    name=""
                    id=""
                    v-model="status"
                    class="form-control form-control-sm"
                  >
                    <option :value="null">All</option>
                    <option value="ACTIVE">ACTIVE</option>
                    <option value="NOTSTARTED">NOTSTARTED</option>
                    <option value="INIITATED">INIITATED</option>
                    <option value="PENDING">PENDING</option>
                    <option value="FAILED">FAILED</option>
                  </select>
                </div>
              </div>
              <div class="col-2">
                <div class="form-group">
                  <br />
                  <button
                    @click="getdataList()"
                    class="btn btn-primary btn-sm margin-right-10"
                  >
                    Search
                  </button>
                  <button @click="clearFilter();status=null;plan=null;getdataList()" class="btn btn-light btn-sm">
                    Clear
                  </button>
                </div>
              </div>
              <div class="col text-right">
                <div class="form-group">
                  <br />
                </div>
              </div>
            </div>
            <table class="table sai-table fixed">
              <thead>
                <tr class="t-head">
                  <th
                    :class="{
                      active_sort: sort_by == 'user' || sort_by == '-user',
                    }"
                    @click="sortBy('user')"
                  >
                    User
                    <i
                      :class="{
                        'fa-sort': sort_by != 'user',
                        'fa-sort-up': sort_by == 'user',
                        'fa-sort-down': sort_by == '-user',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th>
                  <th
                    :class="{
                      active_sort: sort_by == 'title' || sort_by == '-title',
                    }"
                    @click="sortBy('title')"
                  >
                    PLAN
                    <i
                      :class="{
                        'fa-sort': sort_by != 'title',
                        'fa-sort-up': sort_by == 'title',
                        'fa-sort-down': sort_by == '-title',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th>
                  <th
                    :class="{
                      active_sort: sort_by == 'price' || sort_by == '-price',
                    }"
                    @click="sortBy('price')"
                  >
                    price
                    <i
                      :class="{
                        'fa-sort': sort_by != 'price',
                        'fa-sort-up': sort_by == 'price',
                        'fa-sort-down': sort_by == '-price',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th>
                  <!-- <th
                    :class="{
                      active_sort: sort_by == 'packs' || sort_by == '-packs',
                    }"
                    @click="sortBy('packs')"
                  >
                    packs
                    <i
                      :class="{
                        'fa-sort': sort_by != 'packs',
                        'fa-sort-up': sort_by == 'packs',
                        'fa-sort-down': sort_by == '-packs',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th> -->
                  <th
                    :class="{
                      active_sort:
                        sort_by == 'start_date' || sort_by == '-start_date',
                    }"
                    @click="sortBy('start_date')"
                  >
                    start date
                    <i
                      :class="{
                        'fa-sort': sort_by != 'start_date',
                        'fa-sort-up': sort_by == 'start_date',
                        'fa-sort-down': sort_by == '-start_date',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th>
                  <th
                    :class="{
                      active_sort:
                        sort_by == 'end_date' || sort_by == '-end_date',
                    }"
                    @click="sortBy('end_date')"
                  >
                    end date
                    <i
                      :class="{
                        'fa-sort': sort_by != 'end_date',
                        'fa-sort-up': sort_by == 'end_date',
                        'fa-sort-down': sort_by == '-end_date',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th>
                  <th
                    :class="{
                      active_sort:
                        sort_by == 'created_on' || sort_by == '-created_on',
                    }"
                    @click="sortBy('created_on')"
                  >
                    purchase date
                    <i
                      :class="{
                        'fa-sort': sort_by != 'created_on',
                        'fa-sort-up': sort_by == 'created_on',
                        'fa-sort-down': sort_by == '-created_on',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th>
                  <th
                    :class="{
                      active_sort: sort_by == 'status' || sort_by == '-status',
                    }"
                    @click="sortBy('status')"
                  >
                    STATUS
                    <i
                      :class="{
                        'fa-sort': sort_by != 'status',
                        'fa-sort-up': sort_by == 'status',
                        'fa-sort-down': sort_by == '-status',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="!loading_list">
                  <template v-for="item in dataList.results" :key="item.id">
                    <tr>
                      <td>{{ item.user||'-' }}</td>
                      <td>{{ item.plan_details?item.plan_details.title:'-' }} x {{ item.packs }}</td>
                      <td>{{ item.pricing?item.pricing.currency||'':'' }} {{ item.price }}</td>
                      <td>{{ item.start_date }}</td>
                      <td>{{ item.end_date }}</td>
                      <td>{{$filters.fullDate(item.created_on, 'DD-MMM-YYYY, h:mm A')}}</td>
                      <td>
                        <status :status="item.status" />
                        <span
                          class="fa fa-pencil edit-icon f-right"
                          style="cursor pointer;padding:5px;"
                          @click="dataEdit(item)"
                        />
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
              <footer-table
                :data="dataList"
                :loading="loading_list"
                :pageSize="pageSize"
                @action="handleTableAction"
                cols="6"
              ></footer-table>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataMixin from "../mixins/dataMixin";
export default {
  props: ['user'],
  data() {
    return {
      serviceModule: "subscription",
      plansList: null,
      status: null,
      plan: null,
      defaultPayload: {
        title: null,
        description: null,
        price: null,
        credits: null,
        order: null,
        validity: null,
        rules: null,
        status: true,
      },
    };
  },
  mixins: [DataMixin],
  created() {
    this.getPlansList();
  },
  methods: {
    getPlansList() {
      this.$cs.plan.list({}).then(res=>{
        this.plansList = res.data;
      })
    },
    extraParams() {
      let extra = {};
      if(this.status) {
        extra.status = this.status;
      }
      if(this.user) {
        extra.user = this.user.id;
      }
      if(this.plan) {
        extra.plan = this.plan;
      }
      return extra;
    },
  validateData() {
      console.log('validateData');
      const that = this;
      that.error = {};
      var proceed = true;
      if (that.payload.packs === null || that.payload.packs === "") {
        this.error.packs = "Packs is required";
        proceed = false;
      }
      if (that.payload.packs && that.payload.packs < 1) {
        this.error.packs = "Minimun pack should be 1 or >1";
        proceed = false;
      }
      if (that.payload.pack === null || that.payload.pack === "") {
        this.error.pack = "Pack is required";
        proceed = false;
      }
      if(proceed) {
        that.payload.start_date = that.$moment(that.payload.start_date).format('MM-DD-YYYY');
        that.payload.end_date = that.$moment(that.payload.end_date).format('MM-DD-YYYY');
      }
      return proceed;
    },
  },
};
</script>
<style lang="stylus" scoped></style>