<template>
  <div class="users">
    <div class="row margin-0">
      <div class="col-md-12 pad-15">
        <div class="parent margin-bottom-15" v-if="visible_form">
          <div class="parent-body pad-0">
            <div class="row margin-0">
              <div class="col pad-0">
                <h4 v-if="!payload.created_on" class="text-center">Add New Setting</h4>
                <h4 v-if="payload.created_on" class="text-center">Update Setting</h4>
                <div class="row margin-0 margin-bottom-5">
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>ID</b></label>
                      <input
                        type="text"
                        v-model="payload.id"
                        class="form-control form-control-sm"
                        placeholder="id"
                      />
                      <span class="error" v-if="error && error.id">{{
                        error.id
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Description</b></label>
                      <input
                        type="text"
                        v-model="payload.description"
                        class="form-control form-control-sm"
                        placeholder="description"
                      />
                      <span class="error" v-if="error && error.description">{{
                        error.description
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Setting Type</b></label>
                      <select
                        name=""
                        id=""
                        v-model="payload.setting_type"
                        class="form-control form-control-sm"
                      >
                        <option value="APP">APP</option>
                        <option value="OTHER">OTHER</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Value</b></label>
                      <input
                        type="text"
                        v-model="payload.value"
                        class="form-control form-control-sm"
                        placeholder="value"
                      />
                      <span class="error" v-if="error && error.value">{{
                        error.value
                      }}</span>
                    </div>
                  </div>
                  <div class="col-12 text-right">
                    <button
                      v-if="!payload.created_on"
                      @click="newData()"
                      class="btn btn-success btn-sm margin-right-10"
                    >
                      Create Setting
                    </button>
                    <button
                      v-if="payload.created_on"
                      @click="updateData()"
                      class="btn btn-success btn-sm margin-right-10"
                    >
                      Update Setting
                    </button>
                    <button
                      @click="visible_form = false"
                      class="btn btn-light btn-sm"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="parent">
          <div class="parent-head">
            <ul>
              <li :class="{ active: tab === 'Courses' }">Settings</li>
            </ul>
          </div>
          <div class="parent-body">
            <div class="row margin-0 margin-bottom-5">
              <div class="col-2 pad-0">
                <div class="form-group">
                  <label for=""><b>Search Word</b></label>
                  <input
                    type="text"
                    v-model="search_term"
                    placeholder="Search Word"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
              <div class="col-2">
                <div class="form-group">
                  <br />
                  <button
                    @click="getdataList()"
                    class="btn btn-primary btn-sm margin-right-10"
                  >
                    Search
                  </button>
                  <button @click="clearFilter()" class="btn btn-light btn-sm">
                    Clear
                  </button>
                </div>
              </div>
              <div class="col text-right">
                <div class="form-group">
                  <br />
                  <button
                    @click="
                      visible_form = true;
                      resetPayload();
                    "
                    class="btn btn-primary btn-sm"
                  >
                    + Add Setting
                  </button>
                </div>
              </div>
            </div>
            <table class="table sai-table fixed">
              <thead>
                <tr class="t-head">
                  <th>ID</th>
                  <th>DESCRIPTION</th>
                  <th
                    :class="{
                      active_sort: sort_by == 'setting_type' || sort_by == '-setting_type',
                    }"
                    @click="sortBy('setting_type')"
                  >
                    SETTING TYPE
                    <i
                      :class="{
                        'fa-sort': sort_by != 'setting_type',
                        'fa-sort-up': sort_by == 'setting_type',
                        'fa-sort-down': sort_by == '-setting_type',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th>
                  <th
                  >
                    VALUE
                  </th>
                  <th
                    :class="{
                      active_sort: sort_by == 'status' || sort_by == '-status',
                    }"
                    @click="sortBy('status')"
                  >
                    STATUS
                    <i
                      :class="{
                        'fa-sort': sort_by != 'status',
                        'fa-sort-up': sort_by == 'status',
                        'fa-sort-down': sort_by == '-status',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="!loading_list">
                  <template v-for="item in dataList.results" :key="item.id">
                    <tr>
                      <td>{{ item.id || '-' }}</td>
                      <td>{{ item.description }}</td>
                      <td>{{ item.setting_type }}</td>
                      <td>{{ item.value }}</td>
                      <td>{{ item.status }} <span
                          class="fa fa-pencil edit-icon f-right"
                          style="cursor pointer;padding:5px;"
                          @click="dataEdit(item)"
                        /></td>
                    </tr>
                  </template>
                </template>
              </tbody>
              <footer-table
                :data="dataList"
                :loading="loading_list"
                :pageSize="pageSize"
                @action="handleTableAction"
                cols="6"
              ></footer-table>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataMixin from "../mixins/dataMixin";
export default {
  data() {
    return {
      serviceModule: "setting",
      defaultPayload: {
        label: null,
        setting_type: 'APP',
        value: null,
        status: 'ACTIVE'
      },
    };
  },
  mixins: [DataMixin],
  methods: {
    validateData() {
      const that = this;
      that.error = {};
      var proceed = true;
      if (
        that.payload.description === null ||
        that.payload.description === ""
      ) {
        this.error.description = "Description is required";
        proceed = false;
      }
      if (
        that.payload.id === null ||
        that.payload.id === ""
      ) {
        this.error.id = "id is required";
        proceed = false;
      }
      if (that.payload.value === null || that.payload.value === "") {
        this.error.value = "value is required";
        proceed = false;
      }
      console.log(this.error);
      return proceed;
    },
  },
};
</script>
<style lang="stylus" scoped></style>