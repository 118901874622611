<template>
  <div class="trainer pad-15">
    <div class="parent pad-15">
      <div class="row margin-0">
        <div class="col-12 pad-0 pad-top-5 text-center">
            <span class="txt-success f-right"><b>User Profile</b></span><br>
            <img :src="userData && userData.thumbnail?userData.thumbnail:'/user.webp'" style="max-width:200px;margin-bottom:10px;filter:brightness(0.7);" alt=""> 
            <h3>{{userData.name}}</h3>
            <span v-if="userData.status==='ACTIVE'" class="txt-success"><b>ACTIVE</b></span>
            <span v-else-if="userData.status==='INACTIVE'" class="txt-danger"><b>INACTIVE</b></span>
            <span v-else class="txt-info"><b>{{userData.status}}</b></span>
        </div>
        <div class="col-12">
            <user-detail @updated="getUserData()" :user="userData"></user-detail>
        </div>
    </div>
    </div>
  </div>
</template>
<script>
import UserDetail from "./components/UserDetail";
export default {
  props: ["id"],
  components: {
    UserDetail,
  },
  data() {
    return {
      userData: {},
      tab: "About",
      orders: {},
      subjects: {},
      wallet: {},
      referrals: {},
      subjects_sort_by: "",
      pageSize: 20,
      subjectsPageSize: 200,
      wallet_balance: 0,
      preview_document: "latest_photo",
      preview_pic: "latest_photo",
    };
  },
  created() {
    this.getUserData();
    // this.getElectedSubjects();
    // this.getOrders();
    // this.getWallet();
    // this.getNetwork();
  },
  methods: {
    sendSMS() {
      this.$cs.util
        .sendSMS({
          numbers: [parseInt(this.userData.mobile)],
          body: '',
          route: 4,
        })
        .then(
          (res) => {
            alert("SMS send Succesfully");
          },
          (err) => {
            console.log(err.response.data);
          }
        );
    },
    getFileAccess(item) {
      this.$cs.user.getFileAccess(item).then((res) => {
        Object.assign(document.createElement("a"), {
          target: "_blank",
          href: res.data,
        }).click();
        // this.preview_pic = res.data
      });
    },
    getUserData() {
      const that = this;
      that.$cs.user
        .retrive({
          resource: `${that.$cs.user.api}${that.id}/`,
        })
        .then(
          (res) => {
            that.userData = res.data;
          },
          (err) => {
            console.log(err.response.data);
          }
        );
    },
    makeActive(item) {
      const that = this;
      that.$cs.user
        .update({
          resource: `${that.$cs.user.api}${that.id}/active/`,
        })
        .then(
          (res) => {
            that.getUserData();
            alert("User Status Updated Succesfully");
          },
          (err) => {
            console.log(err.response.data);
          }
        );
    },
    getElectedSubjects(page = null) {
      const that = this;
      that.$cs.elected_subject
        .list({
          resource: page,
          pageSize: this.subjectsPageSize,
          params: {
            user: this.id,
          },
        })
        .then(
          (res) => {
            that.subjects = res.data;
          },
          (err) => {
            console.log(err.response.data);
          }
        );
    },
    updateElectedSubjects(item) {
      const that = this;
      that.$cs.elected_subject
        .update({
          resource: `${that.$cs.elected_subject.api}${item.id}/`,
          data: item,
        })
        .then(
          (res) => {
            that.getElectedSubjects();
            console.log(res.data);
          },
          (err) => {
            console.log(err.response.data);
          }
        );
    },
    getOrders() {
      const that = this;
      that.$cs.order
        .list({
          params: {
            trainer: this.id,
          },
        })
        .then(
          (res) => {
            that.orders = res.data;
          },
          (err) => {
            console.log(err.response.data);
          }
        );
    },
    getWallet() {
      const that = this;
      that.$cs.wallet
        .list({
          params: {
            user: this.id,
          },
        })
        .then(
          (res) => {
            that.wallet = res.data;
            res.data.results.forEach((element) => {
              that.wallet_balance += parseFloat(element.amount);
            });
          },
          (err) => {
            console.log(err.response.data);
          }
        );
    },
    getNetwork() {
      const that = this;
      that.$cs.refferal
        .list({
          params: {
            refered_by: this.id,
          },
        })
        .then(
          (res) => {
            that.referrals = res.data;
          },
          (err) => {
            console.log(err.response.data);
          }
        );
    },
  },
};
</script>