<template>
  <div class="users">
    <div class="row margin-0">
      <div class="col-md-12 pad-15">
        <div class="parent margin-bottom-15" v-if="visible_form">
          <div class="parent-body pad-0">
            <div class="row margin-0">
              <div class="col pad-0">
                <h4 v-if="!payload.created_on" class="text-center">Add New Scheduler</h4>
                <h4 v-if="payload.created_on" class="text-center">Update Scheduler</h4>
                <div class="row margin-0 margin-bottom-5">
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>id</b></label>
                      <input
                        type="text"
                        v-model="payload.id"
                        class="form-control form-control-sm"
                        placeholder="id"
                      />
                      <span class="error" v-if="error && error.id">{{
                        error.id
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>label</b></label>
                      <input
                        type="text"
                        v-model="payload.label"
                        class="form-control form-control-sm"
                        placeholder="label"
                      />
                      <span class="error" v-if="error && error.label">{{
                        error.label
                      }}</span>
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="form-group">
                      <label for=""><b>Recurring</b></label>
                      <select
                        name=""
                        id=""
                        v-model="payload.cron.scope"
                        class="form-control form-control-sm"
                      >
                        <option value="MONTHLY">MONTHLY</option>
                        <option value="DAILY">DAILY</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="form-group">
                      <label for=""><b>Hour</b></label>
                      <select
                        name=""
                        id=""
                        v-model="payload.cron.hour"
                        class="form-control form-control-sm"
                      >
                        <template v-for="(itm, idx) in 24" :key="idx">
                          <option :value="itm">{{itm}}</option>
                        </template>
                      </select>
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="form-group">
                      <label for=""><b>Minutes</b></label>
                      <select
                        name=""
                        id=""
                        v-model="payload.cron.min"
                        class="form-control form-control-sm"
                      >
                        <template v-for="(itm, idx) in 60" :key="idx">
                          <option :value="idx">{{idx}}</option>
                        </template>
                      </select>
                    </div>
                  </div>
                  <div class="col-12 text-right">
                    <button
                      v-if="!payload.created_on"
                      @click="newData()"
                      class="btn btn-success btn-sm margin-right-10"
                    >
                      Create Scheduler
                    </button>
                    <button
                      v-if="payload.created_on"
                      @click="updateData()"
                      class="btn btn-success btn-sm margin-right-10"
                    >
                      Update Scheduler
                    </button>
                    <button
                      @click="visible_form = false"
                      class="btn btn-light btn-sm"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="parent">
          <div class="parent-head">
            <ul>
              <li :class="{ active: tab === 'Courses' }">Reports</li>
            </ul>
          </div>
          <div class="parent-body">
            <div class="row margin-0 margin-bottom-5">
              <div class="col-2 pad-0">
                <div class="form-group">
                  <label for=""><b>Search Word</b></label>
                  <input
                    type="text"
                    v-model="search_term"
                    placeholder="Search Word"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
              <div class="col-2">
                <div class="form-group">
                  <br />
                  <button
                    @click="getdataList()"
                    class="btn btn-primary btn-sm margin-right-10"
                  >
                    Search
                  </button>
                  <button @click="clearFilter()" class="btn btn-light btn-sm">
                    Clear
                  </button>
                </div>
              </div>
              <div class="col text-right">
                <div class="form-group">
                  <br />
                  <button
                    @click="$router.push('/reports/new/')"
                    class="btn btn-primary btn-sm"
                  >
                    + New Report
                  </button>
                </div>
              </div>
            </div>
            <table class="table sai-table fixed">
              <thead>
                <tr class="t-head">
                  <th>JOB NAME</th>
                  <th>Description</th>
                  <th>Runs on Every</th>
                  <th>Last Processed on</th>
                  <th>status</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="!loading_list">
                  <template v-for="item in dataList.results" :key="item.id">
                    <tr>
                      <td>{{ item.id }}</td>
                      <td>{{ item.label }}</td>
                      <td>{{ item.cron.scope }}, {{ item.cron.hour }}hr, {{ item.cron.min }}mins</td>
                      <td>{{ item.last_processed || '-' }}</td>
                      <td>
                        {{ item.status }}
                        <span
                          class="fa fa-pencil edit-icon f-right"
                          style="cursor pointer;padding:5px;"
                          @click="dataEdit(item)"
                        />
                        </td>
                    </tr>
                  </template>
                </template>
              </tbody>
              <footer-table
                :data="dataList"
                :loading="loading_list"
                :pageSize="pageSize"
                @action="handleTableAction"
                cols="6"
              ></footer-table>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataMixin from "../mixins/dataMixin";
export default {
  data() {
    return {
      serviceModule: "report",
      defaultPayload: {
        label: null,
        query: null,
        status: null,
        status: 'ACTIVE',
      },
    };
  },
  mixins: [DataMixin],
  methods: {
    validateData() {
      const that = this;
      that.error = {};
      var proceed = true;
      if (
        that.payload.id === null ||
        that.payload.id === ""
      ) {
        this.error.id = "id is required";
        proceed = false;
      }
      if (
        that.payload.label === null ||
        that.payload.label === ""
      ) {
        this.error.label = "label is required";
        proceed = false;
      }
      console.log(this.error);
      return proceed;
    },
  },
};
</script>
<style lang="stylus" scoped></style>