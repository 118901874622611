<template>
    <div>
        <table v-if="dataList" class="table table-bordered table-sm margin-0">
            <template v-for="(itx, idd) in Object.keys(dataList)" :key="idd">
                <tr v-if="dataList[itx] && idd < range">
                    <th style="width: 35%;" class="pad-5">{{itx}}</th>
                    <td class="pad-5">{{dataList[itx]}}</td>
                </tr>
            </template>
        </table>
        <span class="text-primary c-pointer" @click="range=(Object.keys(dataList).length+1)" v-if="Object.keys(dataList).length > range">Show more</span>
    </div>
</template>
<script>
export default {
    props: ['dataList'],
    data() {
        return {
            range: 2
        }
    }
}
</script>