<template>
    <tr class="pagin-block">
        <td :colspan="cols || 10" class="text-center pad-20">
            <br>
            <img src="/search-in-list.png" style="max-width:50%;"><br>
            <h5><b>No results found.</b></h5><br>
        </td>
    </tr>
</template>
<script>
export default {
    props: ['cols']
}
</script>