<template>
    <div>
        <div class="rating-head">
            <ul class="list-inline pad-0 margin-0" v-for="(i, idx) in 5" :key="idx">
                <li style="float:left;font-size: 19px;color:#ddd;margin-right: 2px;" :style="{color: idx <= ratingData.rating?'#ffc107':'#ddd'}">
                    <span class="fa fa-star"></span>
                </li>
            </ul>
            <span style="padding-left: 5px;font-size: 14px;font-weight:bold;">({{ratingData.rating}})</span>
            <div class="rating-body">
                <div>
                    <template v-if="orders[view]">
                        <template v-for="(key, idx) in Object.keys(orders[view])" :key="idx">
                            <div class="row margin-0 pad-top-5">
                                <div class="col pad-left-0" style="min-width: 200px;">
                                    <span style="padding-left: 5px;font-size: 14px;">{{orders[view][key]}}:</span>
                                </div>
                                <div class="col pad-left-0" style="min-width: 150px;">
                                    <ul class="list-inline pad-0 margin-0" v-for="(i, idx) in 5" :key="idx">
                                        <li style="float:left;font-size: 19px;color:#ddd;margin-right: 2px;" :style="{color: idx <= ratingData[key]?'#ffc107':'#ddd'}">
                                            <span class="fa fa-star"></span>
                                        </li>
                                    </ul>
                                    <span style="padding-left: 5px;font-size: 14px;font-weight:bold;">({{ratingData[key]}})</span>
                                </div>
                            </div>
                        </template>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ["rating", "view"],
    data() {
        return {
            visible: false,
            orders: {
                TEACHER: {
                    Promptness: "Attendance & Promptness",
                    Knowledge: "Subject Knowledge",
                    Practicals: "Practicals & Hands-on"
                },
                STUDENT: {
                    Promptness: "Attendance & Promptness",
                    Understanding: "Subject Understanding",
                    Payment: "On Time Payment"
                }
            }
        }
    },
    computed: {
        ratingData: function() {
            if(this.rating.data) {
                return {
                    rating: this.rating.rating || 0,
                    ...this.rating.data
                };
            } else {
                return this.rating;
            }
        }
    }
}
</script>
<style lang="stylus" scoped>
.rating-head
    position: relative
    .rating-body
        position: absolute
        top: 100%
        right: 0
        width: max-content
        border: 1px solid #ddd
        background-color: #fff
        padding: 5px
        z-index: 1000
        border-radius: 4px
        display: none
    &:hover  .rating-body
        display: block
</style>