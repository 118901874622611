<template>
  <div class="row margin-0">
    <div class="col-md-12 pad-15">
      <div class="row margin-0 margin-bottom-5">
        <div class="col-2 pad-0">
          <div class="form-group">
            <label for=""><b>Search Word</b></label>
            <input
              type="text"
              v-model="search_term"
              placeholder="Search Word"
              class="form-control form-control-sm"
            />
          </div>
        </div>
        <div class="col-2">
          <div class="form-group">
            <label for=""><b>Category</b></label>
            <select
              name=""
              id=""
              v-model="filterCat"
              @change="getDomainsList({parent:filterCat})"
              class="form-control form-control-sm"
            >
              <option :value="null">Select Category</option>
              <template v-if="categoriesList && categoriesList.results">
                <template
                  v-for="(cat, idx) in categoriesList.results"
                  :key="idx"
                >
                  <option :value="cat.id">{{ cat.title }}</option>
                </template>
              </template>
            </select>
          </div>
        </div>
        <div class="col-2 pad-right-0">
          <div class="form-group">
            <label for=""><b>Domain</b></label>
            <select
              name=""
              id=""
              v-model="filterDomain"
              class="form-control form-control-sm"
            >
              <option :value="null">Select Domain</option>
              <template v-if="domainsList && domainsList.results">
                <template
                  v-for="(cat, idx) in domainsList.results"
                  :key="idx"
                >
                  <option :value="cat.id">{{ cat.title }}</option>
                </template>
              </template>
            </select>
          </div>
        </div>
        <div class="col-2">
          <div class="form-group">
            <br />
            <button
              @click="getdataList()"
              class="btn btn-primary btn-sm margin-right-10"
            >
              Search
            </button>
            <button @click="filterDomain=null;filterCat=null;clearFilter()" class="btn btn-light btn-sm">
              clear
            </button>
          </div>
        </div>
        <div class="col text-right">
          <div class="form-group">
            <br />
            <button
              @click="
                visible_form = true;
                resetPayload();
              "
              class="btn btn-primary btn-sm"
            >
              + Add Topic
            </button>
          </div>
        </div>
      </div>
      <table class="table sai-table fixed">
        <thead>
          <tr class="t-head">
            <th>CATEGORY</th>
            <th>DOMAIN</th>
            <th
              :class="{
                active_sort: sort_by == 'title' || sort_by == '-title',
              }"
              @click="sortBy('title')"
            >
              TOPIC
              <i
                :class="{
                  'fa-sort': sort_by != 'title',
                  'fa-sort-up': sort_by == 'title',
                  'fa-sort-down': sort_by == '-title',
                }"
                class="fa float-right"
                style="position: relative; top: 0px"
              />
            </th>
            <th>UNLOCK COINS</th>
            <th>MAX ENQUIRY UNLOCKS</th>
            <th>ANNOUNCMENT POST COINS</th>
            <!-- <th>KEYWORDS</th> -->
            <th>QUESTIONS</th>
            <th
              :class="{
                active_sort: sort_by == 'status' || sort_by == '-status',
              }"
              @click="sortBy('status')"
            >
              STATUS
              <i
                :class="{
                  'fa-sort': sort_by != 'status',
                  'fa-sort-up': sort_by == 'status',
                  'fa-sort-down': sort_by == '-status',
                }"
                class="fa float-right"
                style="position: relative; top: 0px"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-if="!loading_list">
            <template v-for="item in dataList.results" :key="item.id">
              <tr>
                <td>{{ item.parent_detail ? item.parent_detail.category || "-" : "-" }}</td>
                <td>{{ item.parent_detail ? item.parent_detail.domain || "-" : "-" }}</td>
                <td>{{ item.title }}</td>
                <td>{{item.enquiry_coins}}</td>
                <td>{{item.enquiry_unlocks}}</td>
                <td>{{item.announcement_coins}}</td>
                <!-- <td>{{item.unlock}}</td> -->
                <td>{{item.questions.length}}</td>
                <td>
                  <span
                    class="text-success"
                    v-if="item.status === 'ACTIVE'"
                    ><b>ACTIVE</b></span
                  >
                  <span class="text-warning" v-else
                    ><b>{{ item.status }}</b></span
                  >
                  <span
                    class="fa fa-pencil edit-icon f-right"
                    style="cursor pointer;padding:5px;"
                    @click="dataEdit(item)"
                  />
                </td>
              </tr>
            </template>
          </template>
        </tbody>
        <footer-table
          :data="dataList"
          :loading="loading_list"
          :pageSize="pageSize"
          @action="handleTableAction"
          cols="6"
        ></footer-table>
      </table>
      <div class="parent margin-top-15" v-if="visible_form">
        <div class="parent-body pad-0">
          <div class="row margin-0">
            <div class="col pad-0">
              <h4 v-if="!payload.id" class="text-center">Add Topic</h4>
              <h4 v-if="payload.id" class="text-center">Update Topic</h4>
              <div class="row margin-0 margin-bottom-5">
                <div class="col-3">
                  <div class="form-group">
                    <label for=""><b>Category</b></label>
                    <select
                      name=""
                      id=""
                      v-model="selectedCat"
                      @change="getDomainsList({parent:selectedCat})"
                      class="form-control form-control-sm"
                    >
                      <template v-if="categoriesList && categoriesList.results">
                        <template
                          v-for="(cat, idx) in categoriesList.results"
                          :key="idx"
                        >
                          <option :value="cat.id">{{ cat.title }}</option>
                        </template>
                      </template>
                    </select>
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <label for=""><b>Domain</b></label>
                    <select
                      name=""
                      id=""
                      v-model="payload.parent"
                      class="form-control form-control-sm"
                    >
                      <template v-if="domainsList && domainsList.results">
                        <template
                          v-for="(cat, idx) in domainsList.results"
                          :key="idx"
                        >
                          <option :value="cat.id">{{ cat.title }}</option>
                        </template>
                      </template>
                    </select>
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <label for=""><b>Topic Name</b></label>
                    <input
                      type="text"
                      v-model="payload.title"
                      class="form-control form-control-sm"
                      placeholder="Topic"
                    />
                    <span class="error" v-if="error && error.title">{{
                      error.title
                    }}</span>
                  </div>
                </div>
                <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Enquiry Coins</b></label>
                      <input
                        type="number"
                        v-model="payload.enquiry_coins"
                        class="form-control form-control-sm"
                        placeholder="Enquiry Coins"
                      />
                      <span class="error" v-if="error && error.enquiry_coins">{{
                        error.enquiry_coins
                      }}</span>
                    </div>
                  </div>
                <div class="col-3">
                  <div class="form-group">
                    <label for=""><b>Enquiry Unlocks</b></label>
                    <input
                      type="number"
                      v-model="payload.enquiry_unlocks"
                      class="form-control form-control-sm"
                      placeholder="Enquiry Unlocks"
                    />
                    <span class="error" v-if="error && error.enquiry_unlocks">{{
                      error.enquiry_unlocks
                    }}</span>
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <label for=""><b>Announcement Coins</b></label>
                    <input
                      type="number"
                      v-model="payload.announcement_coins"
                      class="form-control form-control-sm"
                      placeholder="Announcement Coins"
                    />
                    <span class="error" v-if="error && error.announcement_coins">{{
                      error.announcement_coins
                    }}</span>
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <label for=""><b>Status</b></label>
                    <select
                      name=""
                      id=""
                      v-model="payload.status"
                      class="form-control form-control-sm"
                    >
                      <option value="ACTIVE">Active</option>
                      <option value="INACTIVE">InActive</option>
                    </select>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label for=""><b>Questions</b></label>
                    <select
                      name=""
                      id=""
                      v-model="payload.questions"
                      class="form-control form-control-sm"
                      multiple
                    >
                      <template v-if="questionnaireList && questionnaireList.results">
                        <template
                          v-for="(cat, idx) in questionnaireList.results"
                          :key="idx"
                        >
                          <option :value="cat.id">{{ cat.question }}</option>
                        </template>
                      </template>
                    </select>
                  </div>
                </div>
                <div class="col-4" v-if="false">
                  <div class="form-group">
                    <label for=""><b>Thumbnail</b></label
                    ><br />
                    <input
                      type="text"
                      disabled
                      v-model="payload.thumbnail"
                      class="form-control form-control-sm"
                    /><br />
                    <input
                      type="file"
                      @change="uploadFile()"
                      style="display: none"
                      id="imgfile"
                      ref="imgFile"
                      class="form-control form-control-sm"
                    />
                    <img
                      :src="payload.thumbnail"
                      class="img-fluid"
                      style="max-height: 10vh"
                      alt=""
                    /><br />
                    <button
                      class="btn btn-primary btn-sm margin-right-10"
                      @click="openFile()"
                    >
                      Change
                    </button>
                    <span v-if="uploadingStatus">{{ uploadingStatus }}</span
                    ><br />
                    <span class="error" v-if="error && error.thumbnail">{{
                      error.thumbnail
                    }}</span>
                  </div>
                </div>
                <div class="col-12 text-right">
                  <button
                    v-if="!payload.id"
                    @click="newData()"
                    class="btn btn-success btn-sm margin-right-10"
                  >
                    Create
                  </button>
                  <button
                    v-if="payload.id"
                    @click="updateData()"
                    class="btn btn-success btn-sm margin-right-10"
                  >
                    Update
                  </button>
                  <button
                    @click="visible_form = false"
                    class="btn btn-light btn-sm"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataMixin from "../../mixins/dataMixin";
export default {
  data() {
    return {
      serviceModule: "course",
      filterCat: null,
      filterDomain: null,
      selectedCat: null,
      questionnaireList: null,
      defaultPayload: {
        title: null,
        course_type: 'TOPIC',
        announcement_coins: -1,
        enquiry_coins: -1,
        enquiry_unlocks: -1,
        questions: [],
        status: "ACTIVE",
      },
    };
  },
  created() {
    this.getCategoryList();
    this.getQuestionsList();
  },
  mixins: [DataMixin],
  methods: {
    extraParams() {
      if(this.filterDomain !== null) {
        return {
          course_type: 'TOPIC',
          parent: this.filterDomain
        } 
      }
      return {
        course_type: 'TOPIC'
      };
    },
    getQuestionsList() {
      const that = this;
      that.$cs.questionnaire.list({}).then(
        (data) => {
          that.questionnaireList = Object.assign(data.data);
        },
        () => {
          console.log("error");
        }
      );
    },
    validateData() {
      const that = this;
      that.error = {};
      var proceed = true;
      if (that.payload.title === null || that.payload.title === "") {
        this.error.title = "Topic is required";
        proceed = false;
      }
      if (that.payload.parent === null || that.payload.parent === "") {
        this.error.parent = "Domain is required";
        proceed = false;
      }
      return proceed;
    },
    dataEdit(item) {
      this.payload = Object.assign({}, item);
      if (this.payload.category && this.payload.category.id) {
        this.payload.category = this.payload.category.id;
      }
      this.visible_form = true;
    },
  },
};
</script>
<style lang="stylus" scoped></style>