<template>
  <div class="users">
    <div class="row margin-0">
      <div class="col-md-12 pad-15">
        <div class="parent margin-bottom-15">
          <div class="parent-body pad-0">
            <div class="row margin-0">
              <div class="col pad-0">
                <h4 v-if="!payload.created_on" class="text-center">
                  Add New Report
                </h4>
                <h4 v-if="payload.created_on" class="text-center">
                  Update Report
                </h4>
                {{payload.query}}
                <div class="row margin-0 margin-bottom-5 margin-top-10">
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>label</b></label>
                      <input
                        type="text"
                        v-model="payload.label"
                        class="form-control form-control-sm"
                        placeholder="label"
                      />
                      <span class="error" v-if="error && error.label">{{
                        error.label
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Table</b></label>
                      <select
                        name=""
                        id=""
                        v-model="payload.query.table"
                        class="form-control form-control-sm"
                        @change="payload.query.rules=[]"
                      >
                        <option value="TRANSACTION">Transaction</option>
                      </select>
                      <span class="error" v-if="error && error.label">{{
                        error.label
                      }}</span>
                    </div>
                  </div>
                  <div class="col-12" v-if="payload.query.table">
                    <label for="">Query</label>
                  </div>
                  <div class="col-12 pad-10 margin-left-15 margin-right-15" style="border: 1px dotted #ddd;" v-if="payload.query.table">
                    <div
                      v-for="(rule, index) in payload.query.rules"
                      :key="index"
                      class="row"
                    >
                      <div class="col-md-2 segment-condition">
                        <div class="form-group">
                          <select
                            :id="'btn-segment-condition-' + index"
                            v-if="index !== 0"
                            v-model="rule.condition"
                          >
                            <option value="AND">AND</option>
                            <option value="OR">OR</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-10" />
                      <div class="col-md-2 segment-condition">
                        <div
                          class="seg-line"
                          :class="{
                            'line-up': index !== 0,
                            'line-up-gap': index === 0,
                          }"
                        >
                          &nbsp;
                        </div>
                        <div
                          class="seg-line line-down"
                          :class="{ 'line-down-gap': index === 0 }"
                        >
                          &nbsp;
                        </div>
                      </div>
                      <div class="col-md-3 seg-val">
                        <div class="form-group">
                          <select
                            :id="'select-segment-source-' + index"
                            v-model="rule.source"
                            class="form-control form-control-sm"
                            :class="{
                              'error-input':
                                error.rules && error.rules[index].source,
                            }"
                          >
                            <option
                              v-for="field in tablesConfig[payload.query.table].fields"
                              :key="field.key"
                              :value="field.key"
                            >
                              {{ field.label }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-2 seg-val">
                        <div class="form-group">
                          <select
                            :id="'select-segment-operator-' + index"
                            class="form-control form-control-sm"
                            v-model="rule.operator"
                            :class="{
                              'error-input':
                                error.rules && error.rules[index].operator,
                            }"
                          >
                            <template v-if="tablesConfig[payload.query.table] && tablesConfig[payload.query.table].options[rule.source]">
                              <option
                                v-for="field in tablesConfig[payload.query.table].options[rule.source].options"
                                :key="field.key"
                                :value="field.key"
                              >{{field}}</option>
                            </template>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-4 seg-val">
                        <div class="form-group">
                          <input
                            :id="'select-segment-value-' + index"
                            v-model="rule.value"
                            class="form-control form-control-sm"
                            :disabled="rule.source===null||rule.operator"
                            :class="{
                              'error-input':
                                error.rules && error.rules[index].value,
                            }"
                            type="text"
                          />
                        </div>
                      </div>
                      <div class="col-md-1 seg-val">
                        <div class="form-group" v-if="index !== 0">
                          <i
                            :id="'btn-segment-delete-' + index"
                            class="fa fa-trash c-pointer"
                            style="color: red"
                            @click="removeRule(index)"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row margin-bottom-20">
                      <div class="col-md-2 segment-condition">
                        <div class="form-group">
                          <select
                            id="btn-segment-newrule"
                            v-model="newCondition"
                            @change="addRule()"
                          >
                            <option value="null" selected>None</option>
                            <option value="AND">AND</option>
                            <option value="OR">OR</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 pad-top-10 text-center">
                    <button class="btn btn-primary btn-sm margin-right-10">Query Results</button>
                    <button v-if="payload.rules && payload.rules.length>0" class="btn btn-success btn-sm margin-right-10">Save</button>
                    <button class="btn btn-plain btn-sm">Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tablesConfig: {
        TRANSACTION: {
          fields: [
            {
              key: "transaction_type",
              label: "Transaction Type"
            },
            {
              key: "message",
              label: "Message"
            },
            {
              key: "created_on",
              label: "Transaction On"
            },
            {
              key: "coins",
              label: "Coins"
            }
          ],
          options: {
            transaction_type: {
              type: 'text',
              options: ["contains", "is equals to", "not equals to"]
            },
            message: {
              type: 'text',
              options: ["contains", "is equals to", "not equals to"]
            },
            created_on: {
              type: 'date',
              options: ["is equals to", "not equals to", ">", "<", ">=", "<="]
            },
            coins: {
              type: 'number',
              options: ["is equals to", "not equals to", ">", "<", ">=", "<="]
            }
          }
        },
      },
      source_fields: [
        {
          label: "Transaction Type",
          name: "transaction_type",
        },
        {
          label: "Last Name",
          name: "last_name",
        },
        {
          label: "Email Address",
          name: "email",
        },
        {
          label: "Loyality Id",
          name: "loyality_id",
        },
      ],
      serviceModule: "report",
      newCondition: null,
      error: {},
      payload: {
        label: null,
        query: {
          table: null,
          rules: [],
        },
        status: "ACTIVE",
      },
    };
  },
  methods: {
    addRule() {
      if (this.newCondition) {
        this.payload.query.rules.push({
          condition: this.newCondition,
          source: null,
          operator: null,
          value: null,
          type: null,
        });
        this.newCondition = null;
      }
    },
    removeRule(id) {
      this.payload.query.rules.splice(id, 1);
    },
    validateData() {
      const that = this;
      that.error = {};
      var proceed = true;
      if (that.payload.id === null || that.payload.id === "") {
        this.error.id = "id is required";
        proceed = false;
      }
      if (that.payload.label === null || that.payload.label === "") {
        this.error.label = "label is required";
        proceed = false;
      }
      console.log(this.error);
      return proceed;
    },
  },
};
</script>
<style lang="stylus" scoped>
.segment-condition {
  & .input-form-group {
    padding: 0px;
  }

  & .seg-line {
    border: 1px solid grey;
    margin-left: 40%;
    display: block;
    position: relative;
    height: calc(50% - 6px);

    &.line-up-gap {
      border: none;
      height: calc(50% - 12px);
    }

    &.line-up {
      border-right: none;
      border-top: none;

      &::before {
        content: ' ';
        display: block;
        background-color: #999;
        position: absolute;
        top: calc(100% - 4px);
        right: -8px;
        height: 8px;
        width: 8px;
        border-radius: 50%;
      }
    }

    &.line-down-gap {
      height: calc(50% + 12px);
    }

    &.line-down {
      border-right: none;
      border-bottom: none;
      margin-top: 12px;

      &::before {
        content: ' ';
        display: block;
        background-color: #999;
        position: absolute;
        bottom: calc(100% - 4px);
        right: -8px;
        height: 8px;
        width: 8px;
        border-radius: 50%;
      }
    }
  }
}

.seg-val {
  padding: 10px 15px;
}

#segment-btn-add {
  padding: 8px;
  background-color: #f3f3f3;
  text-align: center;
  color: #404040;
}

.segments-window {
  height: 75vh;
  overflow-y: auto;

  ::-webkit-scrollbar {
    height: 10px;
    width: 8px;
    border-radius: 4px;
    background: transparent;
    transition: all 0.3s ease;
  }

  ::-webkit-scrollbar:hover {
    background: #c6c7ca;
  }

  ::-webkit-scrollbar-thumb {
    background: #777779;
    border-radius: 4px;
  }
}
</style>