<template>
  <div class="users">
    <div class="row margin-0">
      <div class="col-md-12 pad-15">
        <div class="parent margin-bottom-15" v-if="visible_form">
          <div class="parent-body pad-0">
            <div class="row margin-0">
              <div class="col pad-0">
                <h4 v-if="!payload.created_on" class="text-center">Add New Task</h4>
                <h4 v-if="payload.created_on" class="text-center">Update Task</h4>
                <div class="row margin-0 margin-bottom-5">
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>label</b></label>
                      <input
                        type="text"
                        v-model="payload.label"
                        class="form-control form-control-sm"
                        placeholder="label"
                      />
                      <span class="error" v-if="error && error.label">{{
                        error.label
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Task</b></label>
                      <select
                        name=""
                        id=""
                        v-model="payload.task_name"
                        class="form-control form-control-sm"
                      >
                        <option value="task_transactions">Validate Transactions</option>
                        <option value="task_enquiry_archive">Archieve Enquires</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Starts On</b></label>
                      <input
                        type="datetime-local"
                        v-model="payload.runs_on"
                        class="form-control form-control-sm"
                        placeholder="start_date"
                      />
                      <span class="error" v-if="error && error.runs_on">{{
                        error.runs_on
                      }}</span>
                    </div>
                  </div>
                  <div class="col-12 text-right">
                    <button
                      v-if="!payload.id"
                      @click="newData()"
                      class="btn btn-success btn-sm margin-right-10"
                    >
                      Create Task
                    </button>
                    <button
                      v-if="payload.id"
                      @click="updateData()"
                      class="btn btn-success btn-sm margin-right-10"
                    >
                      Update Task
                    </button>
                    <button
                      @click="visible_form = false"
                      class="btn btn-light btn-sm"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="parent">
          <div class="parent-head">
            <ul>
              <li :class="{ active: tab === 'Courses' }">Tasks</li>
            </ul>
          </div>
          <div class="parent-body">
            <div class="row margin-0 margin-bottom-5">
              <div class="col-2 pad-0">
                <div class="form-group">
                  <label for=""><b>Search Word</b></label>
                  <input
                    type="text"
                    v-model="search_term"
                    placeholder="Search Word"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
              <div class="col-2">
                <div class="form-group">
                  <br />
                  <button
                    @click="getdataList()"
                    class="btn btn-primary btn-sm margin-right-10"
                  >
                    Search
                  </button>
                  <button @click="clearFilter()" class="btn btn-light btn-sm">
                    Clear
                  </button>
                </div>
              </div>
              <div class="col text-right">
                <div class="form-group">
                  <br />
                  <button
                    @click="
                      visible_form = true;
                      resetPayload();
                    "
                    class="btn btn-primary btn-sm"
                  >
                    + New Task
                  </button>
                </div>
              </div>
            </div>
            <table class="table sai-table fixed">
              <thead>
                <tr class="t-head">
                  <th>Label</th>
                  <th>task</th>
                  <th>Starts On</th>
                  <th style="width: 30%;">data</th>
                  <th>activity</th>
                  <th>status</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="!loading_list">
                  <template v-for="item in dataList.results" :key="item.id">
                    <tr>
                      <td>{{ item.label }}</td>
                      <td>{{ item.task_name }}</td>
                      <td>{{ $filters.fullDate(item.runs_on, 'DD MMM, YYYY, h:mm A') }}</td>
                      <td style="width: 30%;"><small-table :data-list="item.data" /></td>
                      <td>
                        <span class="d-block">Start: {{ item.activity?$filters.fullDate(item.activity.started, 'DD MMM, YYYY, h:mm A')||'':'-'}}</span>
                        <span class="d-block">Completed: {{ item.activity?$filters.fullDate(item.activity.completed, 'DD MMM, YYYY, h:mm A')||'':'-'}}</span>
                      </td>
                      <td>{{ item.status }}</td>
                    </tr>
                  </template>
                </template>
              </tbody>
              <footer-table
                :data="dataList"
                :loading="loading_list"
                :pageSize="pageSize"
                @action="handleTableAction"
                cols="6"
              ></footer-table>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataMixin from "../mixins/dataMixin";
export default {
  data() {
    return {
      serviceModule: "task",
      defaultPayload: {
        label: null,
        task_name: null,
        data: {},
        runs_on: null,
      },
    };
  },
  mixins: [DataMixin],
  methods: {
    validateData() {
      const that = this;
      that.error = {};
      var proceed = true;
      if (
        that.payload.country === null ||
        that.payload.country === ""
      ) {
        this.error.country = "Country is required";
        proceed = false;
      }
      if (
        that.payload.symbol === null ||
        that.payload.symbol === ""
      ) {
        this.error.symbol = "Symbol is required";
        proceed = false;
      }
      if (
        that.payload.id === null ||
        that.payload.id === ""
      ) {
        this.error.id = "Currency is required";
        proceed = false;
      }
      if (that.payload.coin_price === null || that.payload.coin_price === "") {
        this.error.coin_price = "Coin Price is required";
        proceed = false;
      }
      console.log(this.error);
      return proceed;
    },
  },
};
</script>
<style lang="stylus" scoped></style>