<template>
    <tr v-if="dataList.results && dataList.results.length>0 && loading_list == false" class="pagin-block">
        <td colspan="6">
            <div class="row">
                <div class="col-md-4">
                    <label
                    for
                    >&nbsp; Showing {{ dataList.start }} to {{ (parseInt(dataList.start - 1) + parseInt(pageSize))>=dataList.count?dataList.count:(parseInt(dataList.start - 1) + parseInt(pageSize)) }} of {{ dataList.count }} Items</label>
                </div>
                <div class="col-md-4 text-center">
                    <button
                    :disabled="dataList.previous===null"
                    class="pagin-button"
                    @click="getdataList(dataList.previous)"
                    >
                    <span class="fa fa-chevron-left"/>
                    </button>
                    <button
                    :disabled="dataList.next===null"
                    class="pagin-button"
                    @click="getdataList(dataList.next)"
                    >
                    <span class="fa fa-chevron-right"/>
                    </button>
                </div>
                <div class="col-md-4 text-right">
                    <label for>Items per page:</label>
                    <select v-model="pageSize" class="pagin-select" @change="getdataList()">
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    <option value="2000">2000</option>
                    </select> &nbsp;
                </div>
            </div>
        </td>
    </tr>
</template>
<script>
export default {
    props: ['dataList', 'pageSize']
}
</script>