<template>
  <tr v-for="i in 1" :key="i" :class="[!loading?'pagin-block':'']">
    <template v-if="loading">
      <td v-for="i in cols || 6" :key="i">
        <div class="loading-card pad-left-30">
          <div class="block1">
            <br />
            <div class="block2" />
            <div class="block3" />
          </div>
        </div>
      </td>
    </template>
    <td v-else-if="data.results && data.results.length > 0" colspan="6">
    <div class="row">
        <div class="col-md-4">
        <label for
            >&nbsp; Showing {{ data.start }} to
            {{
            parseInt(data.start - 1) + parseInt(pageSize) >= data.count
                ? data.count
                : parseInt(data.start - 1) + parseInt(pageSize)
            }}
            of {{ data.count }} Items</label
        >
        </div>
        <div class="col-md-4 text-center">
        <button
            :disabled="data.previous === null"
            class="pagin-button"
            @click="$emit('action', {
              type: 'prev',
              value: data.previous
            })"
        >
            <span class="fa fa-chevron-left" />
        </button>
        <button
            :disabled="data.next === null"
            class="pagin-button"
            @click="$emit('action', {
              type: 'next',
              value: data.next
            })"
        >
            <span class="fa fa-chevron-right" />
        </button>
        </div>
        <div class="col-md-4 text-right">
        <label for>Items per page:</label>
        <select v-model="pageSize" class="pagin-select" @change="$emit('action', {
          type: 'pageSize',
          value: pageSize
        })">
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="25">25</option>
            <option value="100">100</option>
            <option value="500">500</option>
            <option value="1000">1000</option>
            <option value="2000">2000</option>
        </select>
        &nbsp;
        </div>
    </div>
    </td>
    <td v-else :colspan="cols || 10" class="text-center pad-20">
        <br>
        <img src="/search-in-list.png" style="max-width:50%;"><br>
        <h5><b>No results found.</b></h5><br>
    </td>
  </tr>
</template>
<script>
export default {
  props: ["data", "cols", "loading", "pageSize"],
};
</script>