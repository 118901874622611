import { createApp } from 'vue'
import axios from 'axios';
import App from './App.vue'
import Services from './services/services.vue'
import router from './router'
import store from './store'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.styl'
import swal from 'sweetalert'
import moment from 'moment'
import NoDataTable from "./components/NoDataTable";
import LoadingTable from "./components/LoadingTable";
import PaginTable from "./components/PaginTable";
import FooterTable from "./components/FooterTable";
import RatingDetail from "@/views/components/RatingDetail";
import SmallTable from "@/views/components/SmallTable";
import status from "@/views/components/status";

import DataMixin from './mixins/dataMixin'

axios.interceptors.request.use(
  (config) => {
    if ((config.headers['Content-Type'] && config.headers['Content-Type'].startsWith('application/json')) || !config.headers['Content-Type']) {
      // eslint-disable-next-line
      if(localStorage.getItem('Token')) {
        config.headers.Authorization = `Bearer ${localStorage.getItem('Token')}` || '';
      }
    }
    return config;
  },
  error => Promise.reject(error),
);

axios.interceptors.response.use(null, (error) => {
  if (error.config && error.response && error.response.status === 401) {
    localStorage.clear();
    window.location.href = '/login/';
  }
  return Promise.reject(error);
});

const app = createApp(App);
app.mixin(DataMixin);
// app.config.globalProperties.$mixins = {
//   DataMixin
// };
app.component('NoDataTable', NoDataTable);
app.component('LoadingTable', LoadingTable);
app.component('PaginTable', PaginTable);
app.component('FooterTable', FooterTable);
app.component('RatingDetail', RatingDetail);
app.component('SmallTable', SmallTable);
app.component('status', status);
app.config.globalProperties.$cs = Services;
app.config.globalProperties.$alert = swal;
app.config.globalProperties.$moment = moment;
app.config.globalProperties.$filters = {
  fullDate(value, format="DD, MMM, YYYY") {
    return moment(value).format(format)
  },
  fullDateTime(value, format="DD, MMM, YYYY hh:mm A") {
    return moment(value).format(format)
  },
  exactDay(value, minus, format="ddd, DD, MMM, YYYY") {
    return moment(value).add(minus, 'days').format(format)
  },
  gstIncluded(value, gst) {
    // GST Amount = Original Cost – (Original Cost * (100 / (100 + GST% ) ) )
    // Net Price = Original Cost – GST Amount
    const gstAmount = value - (value * (100/(100 + gst)));
    const netAmount = value - gstAmount;
    return `${netAmount.toFixed(2)} + ${gstAmount.toFixed(2)} = ${value}`;
  }
}
app.config.devtools = true
app.use(store).use(router).mount('#app');
