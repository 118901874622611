<template>
    <tr v-for="i in 6" :key="i">
        <td v-for="i in (cols || 6)" :key="i">
            <div class="loading-card pad-left-30">
                <div class="block1">
                <br>
                <div class="block2"/>
                <div class="block3"/>
                </div>
            </div>
        </td>
    </tr>
</template>
<script>
export default {
    props: ['cols']
}
</script>