<template>
  <div class="users">
    <div class="row margin-0">
      <div class="col-md-12 pad-15">
        <div class="parent">
          <div class="parent-head">
            <ul>
              <li
                :class="{ active: tab === 'Categories' }"
                @click="tab = 'Categories'"
              >
                Categories
              </li>
               <li
                :class="{ active: tab === 'Domains' }"
                @click="tab = 'Domains'"
              >
                Domains
              </li>
              <li
                :class="{ active: tab === 'Topics' }"
                @click="tab = 'Topics'"
              >
                Topics
              </li>
              <li
                :class="{ active: tab === 'Courses' }"
                @click="tab = 'Courses'"
              >
                Courses
              </li>
            </ul>
          </div>
          <category v-if="tab === 'Categories'"></category>
          <cats v-if="tab === 'Domains'"></cats>
          <sub-cat
            :categories-list="categoriesList"
            v-if="tab === 'Topics'"
          ></sub-cat>
          <div class="parent-body" v-if="tab === 'Courses'">
            <div class="row margin-0 margin-bottom-5">
              <div class="col-2 pad-0">
                <div class="form-group">
                  <label for=""><b>Search Word</b></label>
                  <input
                    type="text"
                    v-model="search_term"
                    placeholder="Search Word"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
              <div class="col-2">
                <div class="form-group">
                  <label for=""><b>Category</b></label>
                  <select
                    name=""
                    id=""
                    v-model="filterCat"
                    @change="getDomainsList({parent:filterCat})"
                    class="form-control form-control-sm"
                  >
                    <option :value="null">Select Category</option>
                    <template v-if="categoriesList && categoriesList.results">
                      <template
                        v-for="(cat, idx) in categoriesList.results"
                        :key="idx"
                      >
                        <option :value="cat.id">{{ cat.title }}</option>
                      </template>
                    </template>
                  </select>
                </div>
              </div>
              <div class="col-2 pad-right-0">
                <div class="form-group">
                  <label for=""><b>Domain</b></label>
                  <select
                    name=""
                    id=""
                    v-model="filterDomain"
                    @change="getTopicsList({parent:filterDomain})"
                    class="form-control form-control-sm"
                  >
                    <option :value="null">Select Domain</option>
                    <template v-if="domainsList && domainsList.results">
                      <template
                        v-for="(cat, idx) in domainsList.results"
                        :key="idx"
                      >
                        <option :value="cat.id">{{ cat.title }}</option>
                      </template>
                    </template>
                  </select>
                </div>
              </div>
              <div class="col-2 pad-right-0">
                <div class="form-group">
                  <label for=""><b>Topic</b></label>
                  <select
                    name=""
                    id=""
                    v-model="filterTopic"
                    class="form-control form-control-sm"
                  >
                    <option :value="null">Select Topic</option>
                    <template
                      v-if="topicsList && topicsList.results"
                    >
                      <template
                        v-for="(cat, idx) in topicsList.results"
                        :key="idx"
                      >
                        <option :value="cat.id">{{ cat.title }}</option>
                      </template>
                    </template>
                  </select>
                </div>
              </div>
              <div class="col-2">
                <div class="form-group">
                  <br />
                  <button
                    @click="getdataList()"
                    class="btn btn-primary btn-sm margin-right-10"
                  >
                    Search
                  </button>
                  <button @click="filterTopic=null;filterCat=null;filterDomain=null;clearFilter()" class="btn btn-light btn-sm">
                    Clear
                  </button>
                </div>
              </div>
              <div class="col text-right">
                <div class="form-group">
                  <br />
                  <button
                    @click="
                      visible_form = true;
                      resetPayload();
                    "
                    class="btn btn-primary btn-sm"
                  >
                    + Add Course
                  </button>
                </div>
              </div>
            </div>
            <table class="table sai-table fixed">
              <thead>
                <tr class="t-head">
                  <th>CATEGORY</th>
                  <th>DOMAIN</th>
                  <th>TOPIC</th>
                  <th
                    :class="{
                      active_sort: sort_by == 'title' || sort_by == '-title',
                    }"
                    @click="sortBy('title')"
                  >
                    COURSE
                    <i
                      :class="{
                        'fa-sort': sort_by != 'title',
                        'fa-sort-up': sort_by == 'title',
                        'fa-sort-down': sort_by == '-title',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th>
                  <th>KEYWORDS</th>
                  <th>UNLOCK COINS</th>
                  <th>MAX ENQUIRY UNLOCKS</th>
                  <th>ANNOUNCMENT POST COINS</th>
                  <th>QUESTIONS</th>
                  <th
                    :class="{
                      active_sort: sort_by == 'status' || sort_by == '-status',
                    }"
                    @click="sortBy('status')"
                  >
                    STATUS
                    <i
                      :class="{
                        'fa-sort': sort_by != 'status',
                        'fa-sort-up': sort_by == 'status',
                        'fa-sort-down': sort_by == '-status',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th>
                  <!-- <th></th> -->
                </tr>
              </thead>
              <tbody>
                <template v-if="!loading_list">
                  <template v-for="item in dataList.results" :key="item.id">
                    <tr>
                      <td>{{ item.parent_detail ? item.parent_detail.category || "-" : "-" }}</td>
                      <td>{{ item.parent_detail ? item.parent_detail.domain || "-" : "-" }}</td>
                      <td>{{ item.parent_detail ? item.parent_detail.topic || "-" : "-" }}</td>
                      <td>{{ item.title }}</td>
                      <td>
                        <ul class="pad-0 margin-0">
                          <template v-for="(tag, index) in item.keywords" :key="index">
                            <li>{{tag}}</li>
                          </template>
                        </ul>
                      </td>
                      <td>{{ item.enquiry_coins }}</td>
                      <td>{{ item.enquiry_unlocks }}</td>
                      <td>{{ item.announcement_coins }}</td>
                      <td>{{ item.questions.length }}</td>
                      <td>
                        <span
                          class="text-success"
                          v-if="item.status === 'ACTIVE'"
                          ><b>ACTIVE</b></span
                        >
                        <span class="text-warning" v-else
                          ><b>{{ item.status }}</b></span
                        >
                        <span
                          class="fa fa-pencil edit-icon f-right"
                          style="cursor pointer;padding:5px;"
                          @click="dataEdit(item)"
                        />
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
              <footer-table
                :data="dataList"
                :loading="loading_list"
                :pageSize="pageSize"
                @action="handleTableAction"
                cols="4"
              ></footer-table>
            </table>
          </div>
        </div>
        <div class="parent margin-bottom-15" v-if="visible_form">
          <div class="parent-body pad-0">
            <div class="row margin-0">
              <div class="col pad-0">
                <h4 v-if="!payload.id" class="text-center">Add New Course</h4>
                <h4 v-if="payload.id" class="text-center">Update Course</h4>
                <div class="row margin-0 margin-bottom-5">
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Category</b></label>
                      <select
                        name=""
                        id=""
                        v-model="filterCat"
                        @change="getDomainsList({parent:filterCat})"
                        class="form-control form-control-sm"
                      >
                        <option :value="null">Select Category</option>
                        <template v-if="categoriesList && categoriesList.results">
                          <template
                            v-for="(cat, idx) in categoriesList.results"
                            :key="idx"
                          >
                            <option :value="cat.id">{{ cat.title }}</option>
                          </template>
                        </template>
                      </select>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Domain</b></label>
                      <select
                        name=""
                        id=""
                        v-model="filterDomain"
                        @change="getTopicsList({parent:filterDomain})"
                        class="form-control form-control-sm"
                      >
                        <option :value="null">Select Domain</option>
                        <template v-if="domainsList && domainsList.results">
                          <template
                            v-for="(cat, idx) in domainsList.results"
                            :key="idx"
                          >
                            <option :value="cat.id">{{ cat.title }}</option>
                          </template>
                        </template>
                      </select>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Topic</b></label>
                      <select
                        name=""
                        id=""
                        v-model="payload.parent"
                        class="form-control form-control-sm"
                      >
                        <option :value="null">Select Topic</option>
                        <template
                          v-if="topicsList && topicsList.results"
                        >
                          <template
                            v-for="(cat, idx) in topicsList.results"
                            :key="idx"
                          >
                            <option :value="cat.id">{{ cat.title }}</option>
                          </template>
                        </template>
                      </select>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Course</b></label>
                      <input
                        type="text"
                        v-model="payload.title"
                        class="form-control form-control-sm"
                        placeholder="Course Name"
                      />
                      <span class="error" v-if="error && error.title">{{
                        error.title
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Enquiry Coins</b></label>
                      <input
                        type="number"
                        v-model="payload.enquiry_coins"
                        class="form-control form-control-sm"
                        placeholder="Enquiry Coins"
                      />
                      <span class="error" v-if="error && error.enquiry_coins">{{
                        error.enquiry_coins
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Enquiry Unlocks</b></label>
                      <input
                        type="number"
                        v-model="payload.enquiry_unlocks"
                        class="form-control form-control-sm"
                        placeholder="Enquiry Unlocks"
                      />
                      <span class="error" v-if="error && error.enquiry_unlocks">{{
                        error.enquiry_unlocks
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Announcement Coins</b></label>
                      <input
                        type="number"
                        v-model="payload.announcement_coins"
                        class="form-control form-control-sm"
                        placeholder="Announcement Coins"
                      />
                      <span
                        class="error"
                        v-if="error && error.announcement_coins"
                        >{{ error.announcement_coins }}</span
                      >
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Status</b></label>
                      <select
                        name=""
                        id=""
                        v-model="payload.status"
                        class="form-control form-control-sm"
                      >
                        <option value="ACTIVE">Active</option>
                        <option value="INACTIVE">InActive</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for=""><b>Questions</b></label>
                      <select
                        name=""
                        id=""
                        v-model="payload.questions"
                        class="form-control form-control-sm"
                        multiple
                      >
                        <template
                          v-if="questionnaireList && questionnaireList.results"
                        >
                          <template
                            v-for="(cat, idx) in questionnaireList.results"
                            :key="idx"
                          >
                            <option :value="cat.id">{{ cat.question }}</option>
                          </template>
                        </template>
                      </select>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for=""><b>Keywords</b></label>
                      <template v-for="(item, index) of payload.keywords" :key="index">
                        <div style="display: flex;">
                          <input
                            type="text"
                            v-model="payload.keywords[index]"
                            class="form-control form-control-sm margin-bottom-5"
                            placeholder="Enter Line"
                          />
                          <span @click="removingOption(index)" class="fa fa-trash margin-left-10 margin-top-5 text-danger c-pointer"></span>
                        </div>
                      </template>
                      <div class="pad-top-10">
                        <button class="btn btn-sm btn-primary" @click="payload.keywords.push('')">+ Add</button>
                      </div>
                    </div>
                  </div>
                  <div class="col-4" v-if="false">
                    <div class="form-group">
                      <label for=""><b>Thumbnail</b></label
                      ><br />
                      <input
                        type="text"
                        disabled
                        v-model="payload.thumbnail"
                        class="form-control form-control-sm"
                      /><br />
                      <input
                        type="file"
                        @change="uploadFile()"
                        style="display: none"
                        id="imgfile"
                        ref="imgFile"
                        class="form-control form-control-sm"
                      />
                      <img
                        :src="payload.thumbnail"
                        class="img-fluid"
                        style="max-height: 10vh"
                        alt=""
                      /><br />
                      <button
                        class="btn btn-primary btn-sm margin-right-10"
                        @click="openFile()"
                      >
                        Change
                      </button>
                      <span v-if="uploadingStatus">{{ uploadingStatus }}</span
                      ><br />
                      <span class="error" v-if="error && error.thumbnail">{{
                        error.thumbnail
                      }}</span>
                    </div>
                  </div>
                  <div class="col-12 text-right">
                    <button
                      v-if="!payload.id"
                      @click="newData()"
                      class="btn btn-success btn-sm margin-right-10"
                    >
                      Add New Course
                    </button>
                    <button
                      v-if="payload.id"
                      @click="updateData()"
                      class="btn btn-success btn-sm margin-right-10"
                    >
                      Update Course
                    </button>
                    <button
                      @click="visible_form = false"
                      class="btn btn-light btn-sm"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cats from "./components/Cats";
import SubCat from "./components/SubCat";
import { mapState } from "vuex";
import DataMixin from "../mixins/dataMixin";
import Category from './components/Category.vue';
export default {
  mixins: [DataMixin],
  components: {
    Category,
    Cats,
    SubCat
  },
  data() {
    return {
      serviceModule: "course",
      dataList: {
        results: [],
      },
      filterCat: null,
      filterDomain: null,
      filterTopic: null,
      selectedProduct: null,
      questionnaireList: null,
      loading_list: false,
      pageSize: 20,
      sort_by: "-created_on",
      search_term: "",
      tab: "Categories",
      user_type: "all",
      categoriesList: null,
      subCategoriesList: null,
      selectedCat: null,
      selectedSubCat: null,
      defaultPayload: {
        category: null,
        title: null,
        course_type: 'COURSE',
        announcement_coins: -1,
        enquiry_coins: -1,
        enquiry_unlocks: -1,
        questions: [],
        status: "ACTIVE",
      },
      visible_form: false,
      error: {},
    };
  },
  computed: {
    ...mapState({
      profile: (state) => state.profile,
    }),
  },
  created() {
    this.getdataList();
    this.getCategoryList();
    this.getQuestionsList();
  },
  methods: {
    extraParams() {
      let params = {};
      if(this.filterTopic !== null) {
        params.parent= this.filterTopic;
      }
      console.log(params);
      return {
        ...params,
        course_type: 'COURSE'
      };
    },
    getQuestionsList() {
      const that = this;
      that.$cs.questionnaire.list({}).then(
        (data) => {
          that.questionnaireList = Object.assign(data.data);
        },
        () => {
          console.log("error");
        }
      );
    },
    // getdataList(page = null) {
    //   const that = this;
    //   let params = {
    //     sort: that.sort_by,
    //     search: that.search_term,
    //   };
    //   that.user_type != "all" ? (params.user_type = this.user_type) : null;
    //   that.$cs.course
    //     .list({
    //       resource: page,
    //       pageSize: that.pageSize,
    //       page,
    //       params,
    //     })
    //     .then(
    //       (data) => {
    //         that.dataList = Object.assign(data.data);
    //       },
    //       () => {
    //         console.log("error");
    //       }
    //     );
    // },
    // sortBy(field) {
    //   if (field === this.sort_by || this.sort_by === `-${field}`) {
    //     if (!this.sort_by.startsWith("-")) {
    //       this.sort_by = `-${field}`;
    //     } else {
    //       this.sort_by = field;
    //     }
    //   } else {
    //     this.sort_by = field;
    //   }
    //   this.getdataList();
    // },
    // clearFilter() {
    //   this.user_type = "all";
    //   this.search_term = "";
    //   this.getdataList();
    // },
    validateData() {
      const that = this;
      that.error = {};
      var proceed = true;
      if (
        that.payload.parent === null ||
        that.payload.parent === ""
      ) {
        this.error.parent = "Topic is required";
        proceed = false;
      }
      if (that.payload.title === null || that.payload.title === "") {
        this.error.title = "Course is required";
        proceed = false;
      }
      console.log(this.error);
      return proceed;
    },
    newData() {
      const that = this;
      if (this.validateData()) {
        that.processing = true;
        that.$cs.course.create({ data: that.payload }).then(
          (res) => {
            that.getdataList();
            that.error = {};
            that.visible_form = false;
          },
          (err) => {
            if (err.response.status === 400) {
              that.error = err.response.data;
            }
          }
        );
      }
    },
    updateData() {
      const that = this;
      if (this.validateData()) {
        that.$cs.course
          .update({
            data: that.payload,
            resource: `${that.$cs.course.api}${that.payload.id}/`,
          })
          .then(
            (res) => {
              that.getdataList();
              that.error = {};
              that.visible_form = false;
            },
            (err) => {
              if (err.response.status === 400) {
                that.error = err.response.data;
              }
            }
          );
      }
    },
    resetPayload() {
      this.payload = {
        category: null,
        title: null,
        course_type: 'COURSE',
        announcement_coins: -1,
        enquiry_coins: -1,
        enquiry_unlocks: -1,
        questions: [],
        status: "ACTIVE",
      };
      this.error = {};
    },
    dataEdit(item) {
      this.payload = Object.assign({}, item);
      if (this.payload.parent && this.payload.parent.id) {
        this.payload.parent = this.payload.parent.id;
      }
      // if (this.payload.sub_category && this.payload.sub_category.id) {
      //   this.payload.sub_category = this.payload.sub_category.id;
      // }
      this.visible_form = true;
    },
    removingOption(index) {
      this.payload.keywords.splice(index, 1);
    }
  },
};
</script>
<style lang="stylus" scoped></style>